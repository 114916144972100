import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Seo from '../../components/Seo'

const GalleryPage = ({ data }) => {
  const { title, slug, images } = data.sanityGallery

  const imageElements = images.map(({ asset }) => (
    <img key={asset.url} src={asset.url} alt={asset.originalFilename} title={asset.originalFilename} />
  ))

  return (
    <StyledGalleryPage>
      <Seo title={title} pathname={`/gallery/${slug.current}`} />
      <h1>{title}</h1>

      <p className="breadcrumbs">
        <Link to='/'>Home</Link> {`>`}&nbsp;
        <Link to='/our-campus/'>Our Campus</Link> {`>`}&nbsp;
        {`${title} Gallery`}
      </p>

      <section className="images">
        {imageElements}
      </section>
    </StyledGalleryPage>
  )
}

export const query = graphql`
  query GalleryPageQuery($id: String) {
    sanityGallery(id: { eq: $id }) {
      title
      slug { current }
      images {
        asset {
          url
          gatsbyImageData
          originalFilename
        }
      }
    }
  }
`

const StyledGalleryPage = styled.main`
  max-width: 1600px;
  margin: auto;
  padding: 6rem 2rem 4rem 2rem;

  section.images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); // repeat(3, 1fr)
    grid-gap: 1.4rem;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: 768px) {
    section.images {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      /* grid-gap: 1.5rem; */
    }
  }

  @media (max-width: 480px) {
    padding: 2rem 1rem;
  }
`

export default GalleryPage